<template>
  <div>
    <Toast position="top-right" />

    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-between align-items-between h-100"
    >
      <div
        class="d-flex align-items-center row"
        style="margin-top: 8px; margin-left: -5px"
      >
        <div class="col-4 w-100">
          <label for="md_consulta">Campanha</label>
          <MultiSelect
            v-model="filtros.campanha"
            :options="opcoesCampanhas"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            placeholder="Filtrar por Campanha"
            class="w-100"
            @input="filtrar"
            optionLabel="text"
            optionValue="value"
          ></MultiSelect>
        </div>
        <div class="col-4 w-100">
          <label for="md_consulta">Fluxo</label>
          <MultiSelect
            v-model="filtros.fluxo"
            :options="opcoesFluxos"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            placeholder="Filtrar por Fluxo"
            class="w-100"
            @input="filtrar"
            optionLabel="text"
            optionValue="value"
          ></MultiSelect>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <b-table
          id="tabelaCallbacks"
          small
          :current-page="currentPage"
          :fields="fields"
          :items="callbacksChatbot_filtrado"
          :per-page="selectQuant.value"
          responsive="sm"
          hover
          primary-key="chatbot_fluxo_id"
          @row-clicked="detalhes"
          sort-by="chatbot_fluxo_id"
          :sort-desc="true"
        >
          <template #cell(chatbot_fluxo_id)="row">
            <label class="mt-3">
              {{ row.item.chatbot_fluxo_id }}
            </label>
          </template>
          <template #cell(fluxo_relacionado)="row">
            <label class="mt-3">
              {{ row.item.cfl_titulo }}
              <i
                class="fas fa-external-link-alt"
                style="font-size: 12px; margin-left: 5px; color: #3699ff"
                @click="abrirFluxoEmNovaAba(row.item.chatbot_fluxo_id)"
              ></i>
            </label>
          </template>
          <template #cell(modelo_campanha_id)="row">
            <label class="mt-3">
              {{ row.item.modelo_campanha_id }}
            </label>
          </template>
          <template #cell(campanha_relacionada)="row">
            <label class="mt-3">
              {{ row.item.mdc_nome }}
            </label>
          </template>

          <template #cell(opcoes)="row">
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>

              <b-dropdown-item
                @click="mostrarDeletarCallbackItemSwal(row.item)"
                href="#"
                >Remover</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="currentPage"
                :total-rows="callbacksChatbot_filtrado.length"
                :per-page="selectQuant.value"
                aria-controls="tabelaCallbacks"
                align="fill"
                @change="salvarConfiguracoes"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiSelect from "primevue/multiselect";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import store from "@/core/services/store/store";
import Swal from "sweetalert2";

export default {
  components: {
    Toast,
    MultiSelect
  },
  data() {
    return {
      callbacksChatbot: [],
      callbacksChatbot_filtrado: [],
      callbackItemSelecionado: {},
      campanhaSelecionada: {},
      campanhaDeletarNome: "",
      carregando: false,
      campanhas: [],
      fluxos: [],
      opcoesCampanhas: [],
      opcoesFluxos: [],
      filtros: {
        fluxo: null,
        campanha: null
      },
      fields: [
        {
          key: "modelo_campanha_id",
          label: "#",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "campanha_relacionada",
          label: "Campanha",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "chatbot_fluxo_id",
          label: "#",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "fluxo_relacionado",
          label: "Callback Customizada",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "opcoes",
          label: "Opções",
          sortable: false,
          thStyle: { width: "60px !important" }
        }
      ],
      itens: [],
      data: {},
      currentPage: 1,
      perPage: 10,
      itemSelecionado: {},
      selectQuant: {
        value: "10",
        options: [
          {
            text: "Exibir 10 registros por página",
            value: "10"
          },
          {
            text: "Exibir 15 registros por página",
            value: "15"
          },
          {
            text: "Exibir 30 registros por página",
            value: "30"
          }
        ]
      }
    };
  },
  methods: {
    async pegarCallbacksChatbot() {
      const response = await ApiService.get(API_LINKS.chatbot_callback);

      return response.data.callbacks.map((callback) => {
        const fluxoRelacionado = this.fluxos.find(
          (fluxo) => fluxo.chatbot_fluxo_id === callback.chatbot_fluxo_id
        );
        const campanhaRelacionada = this.campanhas.find(
          (campanha) =>
            campanha.modelo_campanha_id === callback.modelo_campanha_id
        );
        return {
          ...callback,
          fluxo_relacionado: fluxoRelacionado
            ? fluxoRelacionado.cfl_titulo
            : "Nenhum",
          campanha_relacionada: campanhaRelacionada
            ? campanhaRelacionada.mdc_nome
            : "Nenhum"
        };
      });
    },
    mostrarDeletarCallbackItemSwal(callbackItem) {
      this.callbackItemSelecionado = callbackItem;
      Swal.fire({
        title: "Você deseja remover a callback?",
        text: "Não será possivel reverter essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Callback Removida", `Callback removida.`, "success");
          ApiService.delete(
            API_LINKS.chatbot_callback +
              "/" +
              this.callbackItemSelecionado.chatbot_fluxo_modelo_campanha_id
          ).then(async (_) => {
            this.callbacksChatbot = await this.pegarCallbacksChatbot();
            this.callbacksChatbot_filtrado = JSON.parse(
              JSON.stringify(this.callbacksChatbot)
            );
          });
        }
      });
    },
    detalhes(item) {
      this.callbackItemSelecionado = item;
      // console.log(this.callbackItemSelecionado);
    },
    salvarConfiguracoes(currentPage) {
      store.state.paginacao = {
        perPage: this.selectQuant.value,
        currentPage: currentPage,
        licenciadaSelecionadaFiltrada: this.licenciadaSelecionadaFiltrada
      };
    },
    async pegarCampanhas() {
      const response = await ApiService.get(API_LINKS.campanha);
      return response.data.campanhas;
    },
    async pegarFluxos() {
      const response = await ApiService.get(API_LINKS.chatbot_listar_fluxos);
      return response.data.fluxos;
    },
    filtrar() {
      let linhas_filtradas = this.callbacksChatbot;
      if (
        Array.isArray(this.filtros.campanha) &&
        this.filtros.campanha.length > 0
      ) {
        const arrcampanhas = this.filtros.campanha;

        linhas_filtradas = linhas_filtradas.filter((f) =>
          arrcampanhas.includes(f.mdc_nome)
        );
      }

      if (Array.isArray(this.filtros.fluxo) && this.filtros.fluxo.length > 0) {
        const arrFluxos = this.filtros.fluxo;

        linhas_filtradas = linhas_filtradas.filter((f) =>
          arrFluxos.includes(f.cfl_titulo)
        );
      }

      this.callbacksChatbot_filtrado = linhas_filtradas;
    },
    abrirFluxoEmNovaAba(fluxoId) {
      const routeData = this.$router.resolve({
        name: "automacao.fluxo.editar",
        query: {
          fluxo_id: fluxoId
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  mounted() {
    const init = async () => {
      this.campanhas = await this.pegarCampanhas();
      this.fluxos = await this.pegarFluxos();
      this.callbacksChatbot = await this.pegarCallbacksChatbot();
      this.callbacksChatbot_filtrado = JSON.parse(
        JSON.stringify(this.callbacksChatbot)
      );

      let aux = {};
      for (let c of this.callbacksChatbot) {
        aux[c.mdc_nome] = c.mdc_nome;
      }
      for (let i in aux) {
        this.opcoesCampanhas.push({ value: i, text: i });
      }

      aux = {};
      for (let f of this.callbacksChatbot) {
        aux[f.cfl_titulo] = f.cfl_titulo;
      }
      for (let i in aux) {
        this.opcoesFluxos.push({ value: i, text: i });
      }
    };
    init();
  },
  watch: {
    "selectQuant.value"(newValue) {
      this.salvarConfiguracoes(this.currentPage);
    }
  }
};
</script>

<style scoped>
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaCallbacks .flip-list-move {
  transition: transform 0.5s;
}

.status-on {
  background-color: rgb(97, 191, 3);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
.status-off {
  background-color: rgb(214, 21, 21);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
</style>
